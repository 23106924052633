import { Component, Vue } from "vue-property-decorator";
import { eSexo, SexoLabel } from "../enums/eSexo";
import { eZona, ZonaLabel } from "../enums/eZona";
import { Deficiencia } from "../models/Deficiencia";
import { Escola } from "../models/Escola";
import { Aluno } from "../models/Aluno";
import TableResponsaveis from "../components/TableReponsaveis.vue";
import ModalResponsavel from "../components/ModalResponsavel.vue";
import {
  showLoading,
  closeLoading,
  confirm,
  alertError,
  getDate,
  alertWarning,
  confirmSucess,
  getEstados,
  alertInfo,
  onFilter,
  formatDate,
  validaCpf,
  validaNis,
  validaCNS,
  validaCNSProvisorio,
  possuiValor,
  alertSuccess,
  onFilterString,
  idade,
} from "../utils/functions";
import { Responsavel } from "@/models/Responsavel";
import { eTurno, TurnoLabel } from "@/enums/eTurno";
import { eTipoValidacaoCandidato } from "@/enums/eTipoValidacaoCandidato";
import { getEndereco } from "../services/cepService";
import { getDeficiencias } from "../services/deficienciaService";
import candidatoService from "../services/candidatoService";
import { buscaSerie } from "../services/serieAppService";
import { getAlimentos, getAtividadesFisicas, getDoencas, getMedicamentos } from "../services/fichaMedicaService";
import {
  getAllEscolas,
  getEscolasPreferenciais,
  getEscolasPreMatriculaComCalculoDeDistancia,
  getEscolasPreMatriculaPorTurno,
  obtenhaTodasAsEscolasPreferenciais,
} from "@/services/escolaService";
import { EscolaPreMatriculaDTO } from "../dtos/EscolaPreMatriculaDTO";
import { ConfiguracaoSeriePreMatriculaDTO } from "@/dtos/ConfiguracaoSeriePreMatriculaDTO";
import { CodigoDescricaoDTO } from "@/dtos/CodigoDescricaoDTO";
import { IParametros } from "@/interfaces/IParametros";
import { eParametroPreMatricula } from "@/enums/eParametroPreMatricula";
import { eTipoContemplacao } from "@/enums/eTipoContemplacao";
import router from "@/router";
import rules from "@/utils/rules";
import cidadeService from "@/services/cidadeService";
import { IValidaCandidato } from "@/interfaces/IValidaCandidato";
import { base64ToArrayBuffer, saveByteArray } from "../utils/functions";
import localStorageService from "@/services/localStorageService";
import callCenterService from "@/services/callCenterService";
import { Solicitante } from "@/models/Solicitante";
import { eTipoFinalizacaoAtendimento } from "@/enums/eTipoFinalizacaoAtendimento";
import { CodigoDescricaoTurnoDTO } from "@/dtos/CodigoDescricaoTurnoDTO";
import { EscolaPrefencialDTO } from "@/dtos/EscolaPreferencialDTO";
import { DadosSocioeconomicosDTO } from "@/dtos/DadosSocioeconomicosDTO";
import dadosSocioeconomicosService from "@/services/dadosSocioeconomicosService";
import matriculaService from '@/services/matriculaService';
import { CorRacaLabel, eCorRaca } from "@/enums/eCorRaca";
import { ProcessamentoProtocolo } from "@/models/ProcessamentoProtocolo";
import { http } from "@/request";
import { IResultApi } from "@/interfaces/IResultApi";
import { IEndereco } from "@/interfaces/IEndereco";
import { TurnosVagasMatriculaOnlineDTO } from "@/dtos/TurnosVagasMatriculaOnlineDTO";
import { eParentesco } from "@/enums/eParentesco";

@Component({
  components: {
    TableResponsaveis,
    ModalResponsavel,
  },
})
export default class CadastroAluno extends Vue {
  id!: number | null;
  ehInfantil!: boolean | null;
  ehMilitar!: boolean | null;

  tenantId: number = this.$store.state.tenantId;
  responsaveis!: Responsavel[];
  parametros: IParametros = this.$store.state.parametros;
  usuarioId: string | null = localStorageService.getItem('usuarioId');
  bairrosCodigoDescricao: CodigoDescricaoDTO[] = this.parametros.bairros;
  bairros: string[] = new Array<string>();
  estados: string[] = getEstados();
  cidades: CodigoDescricaoDTO[] = new Array<CodigoDescricaoDTO>();
  escolasPreferenciaisSelecionadas: EscolaPrefencialDTO[] = new Array<EscolaPrefencialDTO>();

  serieFundamental = new ConfiguracaoSeriePreMatriculaDTO();
  deficiente = false;
  possuiIrmao = false;
  aceitaTermo = false;
  haDocApto = false;
  valid = false;
  excedente = false;
  aluno = new Aluno();
  itemsSexo = [eSexo.Masculino, eSexo.Feminino];
  itemsCorRaca = [eCorRaca.NaoDeclarada, eCorRaca.Branca, eCorRaca.Preta, eCorRaca.Parda, eCorRaca.Amarela, eCorRaca.Indigena];
  itemsZona = [eZona.Rural, eZona.Urbana];
  itemsTurno = new Array<eTurno>();
  deficiencias = new Array<Deficiencia>();
  escolas = new Array<Escola>();
  series = new Array<ConfiguracaoSeriePreMatriculaDTO>();
  serieInfantil = "";
  dataNascimento = "";
  loading = false;
  deficiencia = new Deficiencia();
  file = new File([], "");
  maeGestante = null;
  maeGestanteItems = ["Sim", "Não"];
  informarEscolaParaEntregaDeDocumentos = false;
  termo = "";
  escFinal = "";
  turnoFinal = "";
  escolasPreferenciais = new Array<CodigoDescricaoTurnoDTO>();
  listaDeEscola = new Array<CodigoDescricaoTurnoDTO>();
  escolasOrigem = new Array<string>();
  deficienciaDescricao = "";
  exibirOutraDeficiencia = false;
  deficienciasDoAluno = new Array<Deficiencia>();
  doencas = new Array<CodigoDescricaoDTO>();
  alimentos = new Array<CodigoDescricaoDTO>();
  medicamentos = new Array<CodigoDescricaoDTO>();
  atividadesFisicas = new Array<CodigoDescricaoDTO>();
  carregueEscolas = false;
  vagas = new Array<string>();

  escolasParaExcedente = new Array<CodigoDescricaoDTO>();
  escolaExcedente!: CodigoDescricaoDTO | null;

  itemsDadosSocioEconomicos = new DadosSocioeconomicosDTO();

  bloquearCpf = false;
  bloquearSus = false;
  bloquearNis = false;

  loadingTermoDeAceite = false;

  deficienciaInformada = false;

  configuracaoGeral = localStorageService.obtenhaConfiguracaoGeral();

  validate = rules.getRulesCandidato(
    this.aluno,
    this.deficiente,
    this.dataNascimento,
    this.escolasPreferenciais,
    this.escolasPreferenciaisSelecionadas
  );
  mounted() {
    window.addEventListener('beforeunload', this.finalizeAtendimentoNoUnload);
  }

  constructor() {
    super();

    this.aluno.responsaveis = new Array<Responsavel>();
    this.$store.state.responsaveis = new Array<Responsavel>();
    this.responsaveis = this.$store.state.responsaveis;

    const props = localStorageService.obtenhaPropriedades();
    this.id = props.id;
    this.ehInfantil = props.ehInfantil;
    this.ehMilitar = props.ehMilitar;

    this.escolasPreferenciaisSelecionadas = new Array<EscolaPrefencialDTO>();
    for(let i = 0; i < this.parametros.quantidadeOpcoesEscola; i++) {
      this.escolasPreferenciaisSelecionadas.push(new EscolaPrefencialDTO(i));
    }

    this.aluno.maeGestante = false;
    this.popularDeficiencias();
    this.popularEscolas();
    this.populeVagas();

    if (this.parametros.exibirPopUp) {
      alertInfo(this.parametros.popup);
    }
    if (this.parametros.informarDoencas) {
      this.popularDoencas();
    }
    if (this.parametros.informarRestricoesAlimentares) {
      this.popularAlimentos();
    }
    if (this.parametros.informarRestricoesAtividadesFisicas) {
      this.popularAtividadesFisicas();
    }
    if (this.parametros.informarMedicamentosEmUso) {
      this.popularMedicamentos();
    }

    dadosSocioeconomicosService.obtenhaValoresPossiveis(this.itemsDadosSocioEconomicos);
 
    const solicitante: Solicitante = this.$store.state.solicitante;
    if (possuiValor(solicitante) && possuiValor(solicitante.nome)) {
      if(solicitante.ehOProprioCandidato){
        this.aluno.nome = solicitante.nome;
        this.aluno.celularPrincipal = solicitante.telefone || '';
        this.aluno.celularSecundario= solicitante.telefoneSecundario || '';
        this.aluno.cpf = solicitante.cpf || '';
      } else {
        const responsavel = new Responsavel();
        responsavel.nome = solicitante.nome;
        responsavel.celular = solicitante.telefone || '';
        responsavel.celularFixo = solicitante.telefoneSecundario || '';
        responsavel.cpf = solicitante.cpf || '';
        if (solicitante.parentesco) {
          responsavel.parentesco = solicitante.parentesco;
        }
        
        this.$store.commit("addResponsavel", responsavel);
      }
    }
    
    this.popularCandidatoQuandoErroNoProcessamento();
  }

  async popularCandidatoQuandoErroNoProcessamento() {
    const props = localStorageService.obtenhaPropriedades();
    if (props.protocoloComErro) {
      await http.get<IResultApi>('/candidato/errodeprocessamento/' + props.protocoloComErro)
      .then(res => {
        if (res.data.sucesso) {
          this.populaCandidato(res.data.dados)
        }
      });
    }
  }

  async popularEscolas() {
    const response = await getAllEscolas();
    const escolas = response.data;
    escolas.forEach((el) => {
      const escola = new Escola();
      escola.id = el.codigo ?? 0;
      escola.descricao = el.descricao;
      this.escolas.push(escola);
      this.escolasOrigem.push(el.descricao);
    });
  }

  populeVagas() {
    this.vagas = new Array<string>(10);
  }

  async popularDeficiencias() {
    const response = await getDeficiencias();
    response.data.forEach((el: CodigoDescricaoDTO) => {
      const deficiencia = new Deficiencia();
      deficiencia.id = el.codigo;
      deficiencia.descricao = el.descricao;
      this.deficiencias.push(deficiencia);
    });
  }

  async popularDoencas(){
    const response = await getDoencas();
    response.data.forEach((el: CodigoDescricaoDTO) => {
      this.doencas.push(el);
    });
  }

  async popularAlimentos(){
    const response = await getAlimentos();
    response.data.forEach((el: CodigoDescricaoDTO) => {
      this.alimentos.push(el);
    });
  }

  async popularMedicamentos(){
    const response = await getMedicamentos();
    response.data.forEach((el: CodigoDescricaoDTO) => {
      this.medicamentos.push(el);
    });
  }

  async popularAtividadesFisicas(){
    const response = await getAtividadesFisicas();
    response.data.forEach((el: CodigoDescricaoDTO) => {
      this.atividadesFisicas.push(el);
    });
  }

  salvar() {
    if (this.parametros.selecaoDeTurnoPorUnidade && this.escolasPreferenciaisSelecionadas
        .filter(arr => arr.codigo != null && (arr.turno == null || arr.turno == undefined)).length > 0) {
      alertWarning('Existem unidades que não tiveram seus turnos informados. Por favor, revise as unidades e seus turnos.');
      return;
    } 

    if (this.responsaveis.length == 0 || 
      this.responsaveis[0].nome == '' ||
      this.responsaveis[0].nome == null ||
      this.responsaveis[0].nome == undefined) {
        alertWarning('Pelo menos um responsável deve ser informado para prosseguir.');
        return;
      }

    const validacao = (this.$refs.form as Vue & {
      validate: () => boolean;
    }).validate();

    if (validacao) {
      this.confirmaSalvar();
    } else {
      alertWarning(
        "Existem dados obrigatórios não informados, por favor, revise todos os campos para dar continuidade ao cadastro."
      );
    }
  }

  async confirmaSalvar() {
    this.loading = true;

    let error = false;
    if (
      this.parametros.cpfCandidato !== eParametroPreMatricula.Nao &&
      this.parametros.cpfResponsaveis !== eParametroPreMatricula.Nao
    ) {
      this.responsaveis.forEach((resp) => {
        if (resp.cpf && this.aluno.cpf && (resp.cpf == this.aluno.cpf)) error = true;
      });
    }

    if (error) {
      alertError("Não é possível usar o mesmo CPF no Responsável e Aluno");
      this.loading = false;
      return;
    }

    if (this.aluno.beneficiarioDoProgramaAuxilioBrasil && !this.aluno.numeroNIS) {
      alertWarning('Quando o candidato for beneficiário do Programa Bolsa Família seu NIS deve ser informado.');
      this.loading = false;
      return;
    }

    const candidato = {
      preMatriculaId: this.parametros.preMatriculaId,
      alunoId: this.aluno.alunoId,
      tenantId: this.tenantId,
      nome: this.aluno.nome,
      cartaoSus: this.aluno.cartaoSus,
      cpf: this.aluno.cpf,
      sexo: this.aluno.sexo,
      dataNascimento: getDate(this.dataNascimento),
      deficienciaId: 
        this.deficiencia == null ? null : this.deficiencia.id,
      cep: this.aluno.cep,
      logradouro: this.aluno.logradouro,
      complemento: this.aluno.complemento,
      numero: this.aluno.numero,
      bairro: this.aluno.bairro,
      estado: this.aluno.estado,
      zonaResidencia: this.aluno.zonaResidencia,
      cidadeId: this.aluno.cidade.codigo,
      serieId: this.aluno.serieId,
      turno: this.aluno.turno,
      observacao: this.aluno.observacao,
      escolaIdIrmao:
        this.aluno.escolaIdIrmao == null ? null : this.aluno.escolaIdIrmao.id,
      escolaEntregaDeDocumentos:
        this.aluno.escolaEntregaDeDocumentos == null ? null : this.aluno.escolaEntregaDeDocumentos,
      responsaveis: this.$store.state.responsaveis,
      configuracaoSerieId: this.aluno.configuracaoSerieId,
      tempoResidencia: this.aluno.tempoResidencia,
      numeroNIS: this.aluno.numeroNIS,
      maeGestante: this.aluno.maeGestante,
      ehEstudanteDaRede: this.aluno.ehEstudante,
      escolasPreferenciais: this.escolasPreferenciaisSelecionadas.filter(e => e.codigo != null),
      escolaOrigem: this.aluno.escolaOrigem,
      celularPrincipal: this.aluno.celularPrincipal,
      celularSecundario: this.aluno.celularSecundario,
      deficienciaDescricao: this.deficienciaDescricao,
      usuarioId: '',
      solicitante: this.$store.state.solicitante,
      AtendimentoCallCenterId: this.$store.state.atendimento.id,
      doencas: new Array<number>(),
      medicamentosEmUso: new Array<number>(),
      restricoesAlimentares: new Array<number>(),
      restricoesAtividadesFisicas:  new Array<number>(),
      unidadeConsumidora: this.aluno.unidadeConsumidora,
      cartaoSusIrmao: this.aluno.cartaoSusIrmao,
      cpfIrmao: this.aluno.cpfIrmao,
      necessitaDeTransporteEscolar: this.aluno.necessitaDeTransporteEscolar,
      escolaParaContemplacaoDireta: this.aluno.escolaParaContemplacaoDireta,
      rendaFamiliar: this.aluno.rendaFamiliar,
      tipoDaMoradia: this.aluno.tipoDaMoradia,
      situacaoDaMoradia: this.aluno.situacaoDaMoradia,
      quantidadeDePessoasQueMoramNaMesmaCasa: this.aluno.quantidadeDePessoasQueMoramNaMesmaCasa,
      quantidadeDePessoasQueCompoemRendaFamiliar: this.aluno.quantidadeDePessoasQueCompoemRendaFamiliar,
      dependenciasFisicas: this.aluno.dependenciasFisicas,
      tipoDeAbastecimentoDeAgua: this.aluno.tipoDeAbastecimentoDeAgua,
      fonteDeEnergiaEletrica: this.aluno.fonteDeEnergiaEletrica,
      dispositivosDeAcessoInternet: this.aluno.dispositivosDeAcessoInternet,
      destinoDoLixo: this.aluno.destinoDoLixo,
      candidatoDoCadastroAntecipadoId: this.aluno.candidatoDoCadastroAntecipadoId,
      corRaca: this.aluno.corRaca,
      beneficiarioDoProgramaAuxilioBrasil: this.aluno.beneficiarioDoProgramaAuxilioBrasil,
      excedente: this.excedente,
      podeIrParaFilaDeEspera: false,
      resideEmAbrigo: this.aluno.resideEmAbrigo,
      fazendoAcompanhamentoConselhoTutelar: this.aluno.fazendoAcompanhamentoConselhoTutelar,
      naoPossuiPaiDeclarado: this.aluno.naoPossuiPaiDeclarado
    };

    const usuarioId = localStorageService.getItem('usuarioid');
    if (possuiValor(usuarioId) && usuarioId != 'null') {
      candidato.usuarioId = usuarioId ?? '';
    }

    if (this.deficiencia.id == 9999999 && this.deficiencia.descricao == 'Outra') {
      candidato.deficienciaId = null;
    }

    if (this.aluno.doencas != null && this.aluno.doencas.length > 0) {
      this.aluno.doencas.forEach((el) => {
        candidato.doencas.push(el);
      });
    }

    if (this.aluno.medicamentosEmUso != null && this.aluno.medicamentosEmUso.length > 0) {
        this.aluno.medicamentosEmUso.forEach((el) => {
        candidato.medicamentosEmUso.push(el);
      });
    }

    if (this.aluno.alimentos != null && this.aluno.alimentos.length > 0) {
        this.aluno.alimentos.forEach((el) => {
        candidato.restricoesAlimentares.push(el);
      });
    }

    if (this.aluno.atividadesFisicas != null && this.aluno.atividadesFisicas.length > 0) {
        this.aluno.atividadesFisicas.forEach((el) => {
        candidato.restricoesAtividadesFisicas.push(el);
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let arquivo: File | null;
    if (
      this.parametros.anexarComprovanteResidencia !==
        eParametroPreMatricula.Nao &&
      this.file.name != "" &&
      this.file.size > 0
    ) {
      arquivo = this.file;
    } else {
      arquivo = null;
    }

    await this.salvarCadastroDeCandidato(candidato, arquivo);
  }

  async salvarCadastroDeCandidato(candidato: any, arquivo: File | null) {
    await candidatoService
      .addCandidato(candidato, arquivo)
      .then((response) => {
        const result = response.data;

        if (result.sucesso) {
          this.$store.commit("clearState");

          const protocolo: string = result.dados.protocolo;

          // NO FLUXO DE CALL CENTER, não haverá a opção de imprimir o protocolo
          // Caso seja escolhido iniciar outro cadastro, mantém o solicitante no storage para seus dados aparecerem automaticamente na modal de solicitante
          if(this.$store.state.atendimento.id){
            confirmSucess(
              "Cadastro",
              "Candidato cadastrado com sucesso. <br> <b> Protocolo: " + protocolo + "</b> <br> Deseja efetuar um novo cadastro?"
            ).then(async (result) => 
            {
              if (result.isDismissed)
              {
                callCenterService.finalizeAtendimento(this.$store.state.atendimento.id, eTipoFinalizacaoAtendimento.Automaticamente);
                this.$store.commit("clearAtendimento");
              }
              router.push("/");
            });
          }
          else // Fluxo padrão caso o cadastro não tenha sido feito pelo Call Center
          {
            alertSuccess(result.mensagem);
            localStorageService.adicioneProtocolo(new ProcessamentoProtocolo(protocolo, null, null, result.dados.dataCadastro));
            router.push('/protocolos');
          }
        } else if (result.alerta) {
          if (result.dados.limparDataDeNascimento) {
            alertWarning(result.mensagem);
            this.dataNascimento = '';
            this.atualizeEstadoDaPagina();
          } else if (result.dados.confirmaCadastro) {
            confirm(
              "Atenção",
              result.dados.mensagem
            ).then((r) => {
              if (r.isConfirmed) {
                candidato.podeIrParaFilaDeEspera = true;
                this.salvarCadastroDeCandidato(candidato, arquivo);
              }
            });
          } else {
            alertWarning(result.mensagem);
          }
        } else {
          throw result.mensagem;
        }

        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        alertError(err);
      });
  }

  getSexoDescription(item: eSexo) {
    return SexoLabel.get(item);
  }

  obtenhaDescricaoCorRaca(item: eCorRaca) {
    return CorRacaLabel.get(item);
  }

  tipoDeContemplacaoDireta(){
    return this.parametros.tipoContemplacao == eTipoContemplacao.ContemplacaoDireta
  }

  getZonaDescription(item: eZona) {
    return ZonaLabel.get(item);
  }

  getTurnoDescription(item: eTurno) {
    return TurnoLabel.get(item);
  }

  openModalResponsavel() {
    (this.$refs.modalResponsavel as ModalResponsavel & {
      openModal: () => void;
    }).openModal();
  }

  async buscaSerie() {
    if (this.dataNascimento.length < 10) return;

    if (!this.ehInfantil && this.parametros.dataCorte && this.parametros.idadeMinima) {
      const dataCorteTipoDate = new Date(`${this.parametros.dataCorte}`);
      if (idade(getDate(this.dataNascimento)!, dataCorteTipoDate) < this.parametros.idadeMinima) {
        alertWarning(`Esse candidato não pode realizar cadastro, pois a idade mínima configurada é de ${this.parametros.idadeMinima} anos completos até ${dataCorteTipoDate.toLocaleDateString('pt-BR')}.`);
        this.dataNascimento = '';
        return;
      }
    }

    const response = await buscaSerie(
      this.ehInfantil ? getDate(this.dataNascimento) : null,
      this.parametros.preMatriculaId
    );

    if (response.status == 200) {
      const data = response.data;
      if (data.length > 0) {
        if (this.ehInfantil) {
          const serie = data[0];

          this.aluno.serieId = serie.serieId;
          this.serieInfantil = serie.serieNome;
          this.itemsTurno = serie.turnos;
          await this.getEscolasPreferenciais();
        } else {
          this.series = data;
        }
      } else {
        this.series = new Array<ConfiguracaoSeriePreMatriculaDTO>();
        this.aluno.serieId = null;
        this.escolasPreferenciais = new Array<CodigoDescricaoTurnoDTO>();
        this.serieInfantil = "";
        this.itemsTurno = new Array<eTurno>();
      }
    }

    if (this.ehInfantil && (this.aluno.serieId == undefined || this.aluno.serieId == null)) {
      closeLoading();
      this.dataNascimento = "";
      alertWarning(
        "Não foi encontrada série para a data de nascimento informada. Por favor verifique a data de nascimento e tente novamente ou entre em contato com a Secretaria de Educação"
      );
      return;
    }
  }

  cepValido(cep): boolean {
    if (this.parametros.utilizaConfiguracaoDeCep) {
      if (this.parametros.ceps.length == 0) {
        alertError(
          "Não há cep configurado, entre em contato com a secretaria de educação do município."
        );
        return false;
      }
  
      const cepRef = cep.substr(0, 5);
      const existeCep = this.parametros.ceps.indexOf(cepRef);
  
      if (existeCep < 0) {
        this.aluno.cep = "";
        cep = "";
        alertWarning("Cep fora da faixa.");
        return false;
      }
    }

    return true;
  }

  async buscaCep() {
    let cep = this.aluno.cep;
    if (!cep) return;
    cep = cep.replaceAll(".", "").replaceAll("-", "");
    if (cep.length < 8) return;

    if (this.cepValido(cep)) {
      showLoading("Carregando informações do CEP");
      setTimeout(async () => {
        await getEndereco(cep)
          .then(async (response) => {
            const dados = response.data;
            const endereco: IEndereco = dados.endereco;
            this.cidades = dados.cidades;

            if (endereco.cidade) {
              const cid = endereco.cidade.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
              const cidade = this.cidades.filter(cd => cd.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase() == cid.toUpperCase());
              if (cidade && cidade.length > 0) {
                  this.aluno.cidade = cidade[0];
                  this.$forceUpdate();
              }
            }
            this.changeCidade();
  
            if (this.parametros.listagemBairros == eParametroPreMatricula.Nao) {
              this.aluno.bairro = endereco.bairro;
            } else {
              const bairro = this.bairros.filter(cd => cd.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase() == endereco.bairro.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase());
              if (bairro && bairro.length > 0) {
                this.aluno.bairro = bairro[0];
              } else {
                this.aluno.bairro = '';
              }
            }

            this.aluno.complemento = endereco.complemento;
            this.aluno.logradouro = endereco.logradouro;
            this.aluno.estado = endereco.uf;
  
            closeLoading();
        })
        .catch(() => {
            closeLoading();
        });
      }, 1000);
    }
  }

  changeDeficiente() {
    if (!this.deficiente) {
      this.deficiencia = new Deficiencia();
      this.deficienciaDescricao = "";
      this.exibirOutraDeficiencia = false;
      this.deficienciaInformada = false;
      this.getEscolasPreferenciais();
    }
  }

  changeOutraDeficiencia(){
    if (this.deficiencia.id == 9999999) {
      this.exibirOutraDeficiencia = true;
    } else {
      if (this.deficiencia.id > 0 && !this.deficienciaInformada) {
        this.deficienciaInformada = true;
        this.getEscolasPreferenciais();
      }

      this.deficienciaDescricao = "";
      this.exibirOutraDeficiencia = false;
    }
  }

  changeInformacaoOutraDeficiencia() {
    if (this.deficienciaDescricao) {
      if (!this.deficienciaInformada) {
        this.deficienciaInformada = true;
        this.getEscolasPreferenciais();
      }
    } else {
      if (this.deficienciaInformada) {
        this.deficienciaInformada = false;
        this.getEscolasPreferenciais();
      }
    }
  }

  changeEstudaIrmao() {
    if (!this.possuiIrmao)
      this.aluno.escolaIdIrmao = new EscolaPreMatriculaDTO();
  }

  async changeSerieFundamental() {
    this.aluno.serieId = this.serieFundamental.serieId;
    this.aluno.configuracaoSerieId = this.serieFundamental.id;

    if (this.parametros.habilitaSelecaoDeTurno && !this.parametros.selecaoDeTurnoPorUnidade) {
      this.itemsTurno = this.serieFundamental.turnos;
  
      this.escFinal = "";
      this.turnoFinal = "";
      (this.$refs.selectTurno as Vue & {
        clearableCallback: () => void;
      }).clearableCallback();
    }

    await this.getEscolasPreferenciais();
  }

  disabledCep(): boolean {
    return this.dataNascimento == "";
  }

  async populaCandidato(candidato: IValidaCandidato) {
    closeLoading();

    if (candidato.alunoExistente !== null && candidato.alunoExistente !== undefined && candidato.alunoExistente.dados) {
      const dadosDoCandidatoExistente = candidato.alunoExistente.dados;

      // Preenche os dados do aluno de acordo com o que veio da validação
      if (dadosDoCandidatoExistente != null && dadosDoCandidatoExistente != undefined) {

        this.aluno.candidatoDoCadastroAntecipadoId = dadosDoCandidatoExistente.candidatoCadastroAntecipadoId;
        this.aluno.nome = dadosDoCandidatoExistente.nome;
        this.aluno.cpf = dadosDoCandidatoExistente.cpf;
        this.aluno.cartaoSus = dadosDoCandidatoExistente.cartaoSus;
        this.aluno.numeroNIS = dadosDoCandidatoExistente.nis;
        this.aluno.sexo = dadosDoCandidatoExistente.sexo;
        if (dadosDoCandidatoExistente.dataNascimento) {
          this.dataNascimento = formatDate(dadosDoCandidatoExistente.dataNascimento) ?? '';
          this.buscaSerie();
        }

        this.aluno.celularPrincipal = dadosDoCandidatoExistente.telefonePrincipal;
        this.aluno.celularSecundario = dadosDoCandidatoExistente.telefoneSecundario;
        this.deficiente = dadosDoCandidatoExistente.possuiDeficiencia;

        if (dadosDoCandidatoExistente.corRaca) {
          this.aluno.corRaca = dadosDoCandidatoExistente.corRaca;
        }

        if (possuiValor(dadosDoCandidatoExistente.responsavelNome)) {

          const responsavelJaAdicionado = this.responsaveis
            .filter(c => c.cpf != '' && c.cpf == dadosDoCandidatoExistente.responsavelCpf);

          if (!responsavelJaAdicionado || responsavelJaAdicionado.length == 0) {

            const novoResponsavel = new Responsavel();
            novoResponsavel.nome = dadosDoCandidatoExistente.responsavelNome;
            novoResponsavel.cpf = dadosDoCandidatoExistente.responsavelCpf;
            novoResponsavel.celular = dadosDoCandidatoExistente.responsavelTelefonePrincipal;
            novoResponsavel.celularFixo = dadosDoCandidatoExistente.responsavelTelefoneSecundario;
            novoResponsavel.parentesco = dadosDoCandidatoExistente.responsavelParentesco;
  
            this.$store.commit("addResponsavel", novoResponsavel);
          }
        }

        if (dadosDoCandidatoExistente.rendaFamiliar != null) {
          this.aluno.rendaFamiliar = this.itemsDadosSocioEconomicos.rendaFamiliar.filter(c => c.codigo == dadosDoCandidatoExistente.rendaFamiliar.codigo)[0];
        }
        if (dadosDoCandidatoExistente.tipoDaMoradia != null) {
          this.aluno.tipoDaMoradia = this.itemsDadosSocioEconomicos.tiposDeMoradia.filter(c => c.codigo == dadosDoCandidatoExistente.tipoDaMoradia.codigo)[0];
        }
        if (dadosDoCandidatoExistente.situacaoDaMoradia != null) {
          this.aluno.situacaoDaMoradia = this.itemsDadosSocioEconomicos.situacoesDeMoradia.filter(c => c.codigo == dadosDoCandidatoExistente.situacaoDaMoradia.codigo)[0];
        }
        
        this.aluno.quantidadeDePessoasQueMoramNaMesmaCasa = dadosDoCandidatoExistente.quantidadeDePessoasQueMoramNaMesmaCasa;
        this.aluno.dependenciasFisicas = dadosDoCandidatoExistente.dependenciasFisicas;
        this.aluno.tipoDeAbastecimentoDeAgua = dadosDoCandidatoExistente.tipoDeAbastecimentoDeAgua;
        this.aluno.fonteDeEnergiaEletrica = dadosDoCandidatoExistente.fonteDeEnergiaEletrica;
        this.aluno.quantidadeDePessoasQueCompoemRendaFamiliar = dadosDoCandidatoExistente.quantidadeDePessoasQueCompoemRendaFamiliar;
        this.aluno.dispositivosDeAcessoInternet = dadosDoCandidatoExistente.dispositivosDeAcessoInternet;
        this.aluno.destinoDoLixo = dadosDoCandidatoExistente.destinoDoLixo;

        if (dadosDoCandidatoExistente.cep && this.cepValido(dadosDoCandidatoExistente.cep.replaceAll('-', '').replaceAll('.', ''))) {
          this.aluno.cep = dadosDoCandidatoExistente.cep;
          this.aluno.estado = dadosDoCandidatoExistente.estado;
          this.aluno.logradouro = dadosDoCandidatoExistente.logradouro;
          this.aluno.complemento = dadosDoCandidatoExistente.complemento;
          this.aluno.numero = dadosDoCandidatoExistente.numero;
          this.aluno.pontoReferencia = dadosDoCandidatoExistente.pontoReferencia;
          this.aluno.zonaResidencia = dadosDoCandidatoExistente.zonaResidencia;

          await this.popularCidades();
          if (dadosDoCandidatoExistente.cidade) {
            const cidade = this.cidades.filter(cd => cd.codigo == dadosDoCandidatoExistente.cidade.codigo);
            if (cidade && cidade.length > 0) {
              this.aluno.cidade = cidade[0];
              this.changeCidade();
            }
          }

          this.aluno.bairro = dadosDoCandidatoExistente.bairro;
        }

        if (dadosDoCandidatoExistente.cpf) {
          this.bloquearCpf = true;
        }
        if (dadosDoCandidatoExistente.cartaoSus) {
          this.bloquearSus = true;
        }
        if (dadosDoCandidatoExistente.nis) {
          this.bloquearNis = true;
        }
      }

      this.buscaSerie();
      return false;
    }

    if (candidato.existeCandidato) {
      alertWarning(candidato.mensagem);
      this.aluno.cpf = "";
      this.aluno.cartaoSus = "";
      this.aluno.numeroNIS = "";
    }

    if (candidato.retornarParaTelaInicial) {
      this.$router.push('/');
    }
  }

  async verificaCandidatoNis() {
    if (this.aluno.numeroNIS && validaNis(this.aluno.numeroNIS)) {
      await this.verificaCandidato(this.aluno.numeroNIS, eTipoValidacaoCandidato.Nis);
    } else {
      this.aluno.numeroNIS = '';
      alertWarning('Insira um número nis válido.');
    }
  }

  async verificaCandidatoCpf() {
    if (this.aluno.cpf && validaCpf(this.aluno.cpf)) {
      await this.verificaCandidato(this.aluno.cpf, eTipoValidacaoCandidato.CPF);
    } else {
      this.aluno.cpf = '';
      alertWarning('Insira um cpf válido.');
    }
  }

  async verificaCandidatoSus() {
    if (this.aluno.cartaoSus && (validaCNS(this.aluno.cartaoSus) || validaCNSProvisorio(this.aluno.cartaoSus))) {
      await this.verificaCandidato(this.aluno.cartaoSus, eTipoValidacaoCandidato.CartaoSus);
    } else {
      this.aluno.cartaoSus = '';
      alertWarning('Insira um cartão sus válido.');
    }
  }

  async verificaCandidato(texto: string, tipo: eTipoValidacaoCandidato) {
    showLoading("Validando documento...");
    setTimeout(async () => {
      try {
        const parametros = {
            texto: texto,
            preMatriculaId: this.parametros.preMatriculaId,
            ehMilitar: this.ehMilitar || false,
            tipo: tipo
        };
        const response = await candidatoService.validaCandidato(parametros);
  
        const result = response.data; 
        const dados: IValidaCandidato = result.dados;
  
        if (result.sucesso) {
  
          await this.populaCandidato(dados);
  
        } else if (result.alerta) {
          closeLoading();
          alertWarning(dados.mensagem);
  
          switch (tipo) {
            case eTipoValidacaoCandidato.CPF:
              this.aluno.cpf = "";
              break;
            case eTipoValidacaoCandidato.CartaoSus:
              this.aluno.cartaoSus = "";
              break;
            case eTipoValidacaoCandidato.Nis:
              this.aluno.numeroNIS = "";
              break;
          }
  
          if (dados.retornarParaTelaInicial) {
            this.$router.push('/');
          }
        } else {
          throw result.dados.mensagem;
        }
      } catch (err) {
        closeLoading();
      }
    }, 1000);
  }

  ativaForm(): boolean {
    if (!this.parametros.permitirCadastroMaeGestante) return false;
    if (this.maeGestante == null) return true;

    if (this.maeGestante == "Sim") {
      this.aluno.nome = "MAE GESTANTE";
      this.aluno.maeGestante = true;
    } else {
      this.aluno.maeGestante = false;
    }

    return false;
  }

  getDisabledSalvar(): boolean {
    if (this.responsaveis.length == 0) return true;
    if (this.parametros.exibirTermoAceite && !this.aceitaTermo) return true;
    return false;
  }

  getLabelDataNascimento(): string {
    if (this.parametros.permitirCadastroMaeGestante && this.aluno.maeGestante)
      return "Previsão de Nascimento";
    return "Data de Nascimento";
  }

  getLabelCelular(ehSecundario: boolean): string {
    if (ehSecundario) {
      return "Telefone Secundário";
    }
    return "Telefone Principal";
  }

  mostraCampo(campo: string): boolean {
    switch (campo) {
      case "cartaoSus":
        return (
          this.parametros.cartaoSusCandidato !== eParametroPreMatricula.Nao
        );

      case "cpf":
        return this.parametros.cpfCandidato !== eParametroPreMatricula.Nao;

      case "nis":
        return this.parametros.informarNumeroNis !== eParametroPreMatricula.Nao;

      case "anexarComprovante":
        return (
          this.parametros.anexarComprovanteResidencia !==
          eParametroPreMatricula.Nao
        );

      case "tempoMoradia":
        return (
          this.parametros.informarTempoMoradia !== eParametroPreMatricula.Nao
        );

      case "irmaoRede":
        return (
          this.parametros.informarIrmaoNaRede !== eParametroPreMatricula.Nao
        );

      case "ehEstudante":
        return this.parametros.informarJaEstuda !== eParametroPreMatricula.Nao;

      case "tempoResidencia":
        return (
          this.parametros.informarTempoMoradia !== eParametroPreMatricula.Nao
        );

      case "listagemBairros":
        return this.parametros.listagemBairros !== eParametroPreMatricula.Nao;

      case "celularPrincipal":
        return this.parametros.informarCelularPrincipal !== eParametroPreMatricula.Nao;

      case "celularSecundario":
        return this.parametros.informarCelularSecundario !== eParametroPreMatricula.Nao;

      case "unidadeConsumidora":
        return this.parametros.informarUnidadeConsumidora !== eParametroPreMatricula.Nao;

      case "corRaca":
        return this.parametros.corRaca !== eParametroPreMatricula.Nao;

      default:
        return true;
    }
  }

  openVideo() {
    window.open(this.parametros.urlVideo);
  }

  async popularCidades() {
    if (!this.aluno.estado) return;
    this.cidades = new Array<CodigoDescricaoDTO>();
    await cidadeService.getCidades(this.aluno.estado).then((response) => {
      this.cidades = response.data;
    }).catch((error) => {
      alertError(error.response.data);
    });
  }

  changeEscolaParaEntregaDeDocumentos(event: CodigoDescricaoDTO) {
    if(event.codigo >= 0)
      this.aluno.escolaEntregaDeDocumentos = event.codigo;
  }

  getDisabledEscolas(): boolean {
    return this.escolasPreferenciaisSelecionadas.length > 0;
  }

  async getEscolasPreferenciais() {
    this.escolasPreferenciaisSelecionadas.forEach(escola => {
      escola.codigo = null;
      escola.descricao = '';
      escola.turno = null;
      escola.turnosParaSelecao = new Array<CodigoDescricaoDTO>();
    });

    this.escolasPreferenciais.forEach(escola => {
      escola.codigo = null;
      escola.descricao = '';
      escola.turnosVagas = new Array<TurnosVagasMatriculaOnlineDTO>();
    });

    (this.$refs.form as Vue & {
      resetValidation: () => boolean;
    }).resetValidation()

    if (!this.aluno.serieId) return;
    if (!this.parametros.preMatriculaId) return;
    if (this.parametros.habilitaSelecaoDeTurno && this.aluno.turno == null && !this.parametros.selecaoDeTurnoPorUnidade) return;
    if (this.parametros.habilitaSelecaoDeTurno && !this.aluno.munipioOndeDesejaEstudar && this.parametros.podeSelecionarOutroMunicipio) return;

    if(this.parametros.informarEscolaEntregaDocumentos){
      showLoading('Carregando escolas...');
      setTimeout(async () => {
        await getAllEscolas()
        .then((response) => {
          this.listaDeEscola = response.data;
        }).finally(() => {
          closeLoading();
        });
      }, 1000);
    }

    if (this.excedente) {
      showLoading('Carregando escolas...');
      setTimeout(async () => {
        await obtenhaTodasAsEscolasPreferenciais(this.parametros.preMatriculaId, this.aluno.serieId ?? 0, this.aluno.turno, this.aluno.munipioOndeDesejaEstudar?.codigo)
        .then((response) => {
          this.escolasPreferenciais = response.data;
        }).finally(() => {
          closeLoading();
        });
      }, 1000);
    } else if (!this.parametros.habilitaSelecaoDeTurno) {
      getEscolasPreferenciais(this.parametros.preMatriculaId, this.aluno.serieId, this.aluno.munipioOndeDesejaEstudar?.codigo, this.aluno.candidatoDoCadastroAntecipadoId, this.deficienciaInformada)
      .then((response) => {
        this.escolasPreferenciais = response.data; 
      }).catch(()=>{ 
        alertWarning("Erro ao carregar as escolas.") 
      }).finally(()=>{
        this.carregueEscolas = false;
      });
    } else {
      if (this.parametros.selecaoDeTurnoPorUnidade) {
        this.carregueEscolas = true
        getEscolasPreferenciais(
            this.parametros.preMatriculaId, 
            this.aluno.serieId, 
            this.aluno.munipioOndeDesejaEstudar?.codigo, 
            this.aluno.candidatoDoCadastroAntecipadoId, 
            this.deficienciaInformada)
          .then((response) => {
                  this.escolasPreferenciais = response.data; 
                }).catch(()=>{ 
                  alertWarning("Erro ao carregar as escolas.") 
                }).finally(()=>{
                  this.carregueEscolas = false;
                });
      } else if (!this.parametros.calcularDistanciaPeloCep){
        this.carregueEscolas = true
        await  getEscolasPreMatriculaPorTurno(this.parametros.preMatriculaId, 
                                              this.aluno.serieId, 
                                              this.aluno.turno,
                                              this.deficienciaInformada,
                                              this.aluno.munipioOndeDesejaEstudar?.codigo,
                                              this.aluno.candidatoDoCadastroAntecipadoId).then((response) => {
                  this.escolasPreferenciais = response.data;
                }).catch(()=>{ 
                  alertWarning("Erro ao carregar as escolas.") 
                }).finally(()=>{
                  this.carregueEscolas = false;
                });
      } else {
        if (!this.aluno.cep) {
          (this.$refs.selectTurno as Vue & {
            clearableCallback: () => void;
          }).clearableCallback();
          return alertWarning(
            "Digite o cep do aluno para buscar a escola mais próxima"
          );
        }
        if (!this.aluno.serieId && this.parametros.calcularDistanciaPeloCep)
          return alertWarning(
            "Selecione a série do aluno para buscar a escola mais próxima"
          );
          
        this.carregueEscolas = true
        await getEscolasPreMatriculaComCalculoDeDistancia(this.aluno.cep, 
                                                          this.parametros.preMatriculaId, 
                                                          this.aluno.serieId, 
                                                          this.aluno.turno,
                                                          this.aluno.candidatoDoCadastroAntecipadoId)
                .then((response) => {
                  this.escolasPreferenciais = response.data;
                }).catch(()=>{ 
                  alertWarning("Erro ao carregar as escolas.") 
                }).finally(()=>{
                  this.carregueEscolas = false;
                });
      }   
    }
  }

  ativarCampos(): boolean {
    if (!this.parametros.permitirCadastroMaeGestante){
      return true;
    }

    return this.maeGestante != null;
  }

  existeAtendimento(): boolean{     
    return this.$store.state.atendimento.id;
  }

  finalizeAtendimentoNoUnload() {
    this.finalizeAtendimento(eTipoFinalizacaoAtendimento.AutomaticamentePeloNavegador);
  }

  finalizeAtendimento(tipoFinalizacao: eTipoFinalizacaoAtendimento){
    const atendimento = this.$store.state.atendimento;
    if(atendimento.id){
      callCenterService.finalizeAtendimento(atendimento.id, tipoFinalizacao);
    }

    this.$router.push(`/callCenter/${localStorageService.getItem('usuarioid')}`);
  }

  tamanhoDasColunasDasEscolas() {
    let tamanhoTotal = 12;
    if (this.parametros.selecaoDeTurnoPorUnidade) {
      tamanhoTotal -= 4;
    }

    return tamanhoTotal;
  }

  obtenhaEscolas(i: number) {
    if (this.parametros.selecaoDeTurnoPorUnidade) {
      return this.escolasPreferenciais;
    } else {
      const escolasJaSelecionadas = this.escolasPreferenciaisSelecionadas
        .filter(arr => this.hasValue(arr.codigo) && arr.ordem != i)
        .map(arr => {
          return arr.codigo
        });
      return this.escolasPreferenciais?.filter(arr => !escolasJaSelecionadas.includes(arr.codigo))
    }
  }

  obtenhaTurnosDaEscola(i: number, escolaParaAtualizar: EscolaPrefencialDTO) {
    escolaParaAtualizar.turnosParaSelecao = new Array<CodigoDescricaoDTO>();
    if (this.parametros.selecaoDeTurnoPorUnidade) {
      const codigoDaEscola = this.escolasPreferenciaisSelecionadas[i]?.codigo;
      if (codigoDaEscola) {
        const escolaComTurnos = this.escolasPreferenciais?.filter(arr => arr.codigo == codigoDaEscola)[0];
  
        const turnosSelecionados = new Array<eTurno>();
        for (let index = 0; index < i; index++) {
          const escola = this.escolasPreferenciaisSelecionadas[index];
          if (escola?.codigo == codigoDaEscola && escola?.turno != null) {
            turnosSelecionados.push(escola.turno);
          }
        }
        
        const turnosDisponiveis = escolaComTurnos.turnosVagas?.filter(arr => !turnosSelecionados.includes(arr.turno));
        for (const turno of turnosDisponiveis) {
          const cd = new CodigoDescricaoDTO();
          cd.codigo = turno.turno as number;
          cd.descricao = this.obtenhaDescricaoDeTurno(turno.turno, turno.vagas);
          escolaParaAtualizar.turnosParaSelecao.push(cd);
        }
      }
    }
    this.atualizeEstadoDaPagina();
  }

  obtenhaDescricaoDeTurno(turno: eTurno, vagas: number): string {
    if (this.parametros.exibirQuantitativoDeVagasPorUnidadeETurno) {
      if (vagas >= 0) {
        return this.getTurnoDescription(turno as eTurno) + ` (${vagas} vaga${vagas > 1 ? 's': ''})`;
      }
    } else {
      return this.getTurnoDescription(turno as eTurno) ?? '';
    }

    return '';
  }

  onChangeEscolaSelecionada(i: number, e: EscolaPrefencialDTO) {
    const escola = this.escolasPreferenciaisSelecionadas[i];
    escola.turnosParaSelecao = new Array<CodigoDescricaoDTO>();
    escola.codigo = e?.codigo;

    if (!e) {
      escola.turno = null;

      for (const esc of this.escolasPreferenciaisSelecionadas.filter(arr => arr.ordem > i)) {
        esc.codigo = null;
        esc.turno = null;
        this.obtenhaTurnosDaEscola(esc.ordem, esc);
      }
    }

    this.obtenhaTurnosDaEscola(i, escola);
  }

  onChangeTurnoSelecionado(i: number, e: CodigoDescricaoDTO) {
    const escolaSelecionada = this.escolasPreferenciaisSelecionadas[i];
    if (e) {
      if (escolaSelecionada) {
        escolaSelecionada.turno = e.codigo;

        const escola = this.escolasPreferenciaisSelecionadas
          .find(arr => arr.codigo == escolaSelecionada.codigo
            && arr.turno == e.codigo as eTurno
            && arr.ordem != escolaSelecionada.ordem);
        if (escola) {
          escola.turno = null;
          this.obtenhaTurnosDaEscola(escola.ordem, escola);
        }
      }
    } else {
      escolaSelecionada.turno = null;
      for (const esc of this.escolasPreferenciaisSelecionadas.filter(arr => arr.ordem > i)) {
        esc.codigo = null;
        esc.turno = null;
        this.obtenhaTurnosDaEscola(esc.ordem, esc);
      }
    }

    this.obtenhaTurnosDaEscola(i, escolaSelecionada);
  }

  bloquearClearable(i: number) {
    const escola = this.escolasPreferenciaisSelecionadas[i + 1];
    if (escola && this.hasValue(escola.codigo)) {
      return true;
    }

    return false;
  }

  bloquearEscola(i: number) {
    if (i == 0) {
      return false;
    }

    const escola = this.escolasPreferenciaisSelecionadas[i - 1];
    if (escola && escola.codigo != null) {
      if(this.parametros.selecaoDeTurnoPorUnidade) {
        if (this.hasValue(escola.turno)) {
          return false;
        }

        return true;
      }

      return false;
    }

    return true;
  }

  bloquearTurno(i: number) {
    const escola = this.escolasPreferenciaisSelecionadas[i];
    if (escola && this.hasValue(escola.codigo)) {
      return false;
    }

    return true;
  }

  atualizeEstadoDaPagina(){
    // Como algumas propriedades utilizadas nesse controller não são reativas, nem toda alteração é refletida de imediato para a página...
    // Ao utilizar essa função, ativamos o 'change' de uma propriedade reativa para que a tela seja atualizada
    this.carregueEscolas = !this.carregueEscolas;
    this.carregueEscolas = !this.carregueEscolas;
    this.$forceUpdate();
  }

  hasValue(v: number | null) {
    return v != null && v != undefined;
  }

  filtre(item, queryText): boolean {
    return onFilter(item, queryText);
  }

  filtreString(item, queryText): boolean {
    return onFilterString(item, queryText);
  }

  isNumber(evt: KeyboardEvent) {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
    } else {
        return true;
    }
  }

  tamanhoNome() {
    if (this.mostraCampo('cpf')) {
      return "9";
    }

    return "12";
  }

  async baixarAnexoTermoDeAceite() {
    this.loadingTermoDeAceite = true;
    await matriculaService.obtenhaDocumentoDoTermoDeAceite(this.parametros.preMatriculaId)
      .then(res => {
        const data = res.data;
        if (data.sucesso) {
          const blob = base64ToArrayBuffer(data.dados.base64);
          saveByteArray(this.parametros.descricaoArquivoTermoDeAceite, blob);
        } else {
          alertWarning(data.mensagem);
        }
      })
      .catch(() => {
        alertWarning('Erro ao tentar baixar o arquivo, contate o administrador de seu sistema.');
      })
      .finally(() => {
        this.loadingTermoDeAceite = false;
      });
  }

  possuiValor(value: any) {
    return value && value != null && value != undefined && value != '' && value != 'null';
  }

  carregueEscolasParaExcedente() {
    this.escolasParaExcedente = new Array<CodigoDescricaoDTO>();
    this.escolaExcedente = null;

    if (this.excedente) {
      getAllEscolas()
        .then(res => {
          res.data.forEach(escola => {
            const escolaCD = new CodigoDescricaoDTO();
            escolaCD.codigo = escola?.codigo ?? 0;
            escolaCD.descricao = escola.descricao;

            this.escolasParaExcedente.push(escolaCD);
          });
        })
        .catch(() => {
          alertWarning('Erro ao buscar escolas.');
        });
    }
  }

  labelObrigatoria(campo: string) {
    const CLASSE_OBRIGATORIA = 'obrigatorio';
    switch (campo) {
      case 'cpf':
        return this.parametros.cpfCandidato == eParametroPreMatricula.SimObrigatorio && !this.aluno.maeGestante ? CLASSE_OBRIGATORIA : '';
      case 'cartaoSus':
        return this.parametros.cartaoSusCandidato == eParametroPreMatricula.SimObrigatorio && !this.aluno.maeGestante ? CLASSE_OBRIGATORIA : '';
      case 'celularPrincipal':
        return this.parametros.informarCelularPrincipal == eParametroPreMatricula.SimObrigatorio && !this.aluno.maeGestante ? CLASSE_OBRIGATORIA : '';
      case 'celularSecundario':
        return this.parametros.informarCelularSecundario == eParametroPreMatricula.SimObrigatorio && !this.aluno.maeGestante ? CLASSE_OBRIGATORIA : '';
      case 'corRaca':
        return this.parametros.corRaca == eParametroPreMatricula.SimObrigatorio ? CLASSE_OBRIGATORIA : '';
      case 'nis':
        return this.parametros.informarNumeroNis == eParametroPreMatricula.SimObrigatorio || this.aluno.beneficiarioDoProgramaAuxilioBrasil ? CLASSE_OBRIGATORIA : '';
      case 'tempoResidencia':
        return this.parametros.informarTempoMoradia == eParametroPreMatricula.SimObrigatorio ? CLASSE_OBRIGATORIA : '';
      case 'unidadeConsumidora':
        return this.parametros.informarUnidadeConsumidora == eParametroPreMatricula.SimObrigatorio ? CLASSE_OBRIGATORIA : '';
      case 'listagemBairros':
        return this.parametros.listagemBairros == eParametroPreMatricula.SimObrigatorio ? CLASSE_OBRIGATORIA : '';
      case 'anexarComprovante':
        return this.parametros.anexarComprovanteResidencia == eParametroPreMatricula.SimObrigatorio ? CLASSE_OBRIGATORIA : '';
      case 'sexo':
        return !this.aluno.maeGestante ? CLASSE_OBRIGATORIA : '';
      default:
        return '';
    }
  }

  preenchimentoDeEscolaObrigatorio(ordem: number) {
    if (this.parametros.quantidadeOpcoesEscolaObrigatorio > 0
      && (ordem + 1) <= this.parametros.quantidadeOpcoesEscolaObrigatorio) {
        return 'obrigatorio';
    }

    return '';
  }

  changeCidade() {
    if (this.aluno.cidade != null) {
      this.bairros = 
        this
        .bairrosCodigoDescricao
        .filter(bairro => bairro.codigo == this.aluno.cidade.codigo)
        .map(desc => desc.descricao);
    }
    else {
      this.bairros = new Array<string>();
    }
  }

  onChangeCandidatoNaoPossuiPaiDeclarado() {
    this.$store.commit('candidatoNaoPossuiPaiDeclarado', this.aluno.naoPossuiPaiDeclarado);

    if (this.aluno.naoPossuiPaiDeclarado) {
      const responsavelPai = this.$store.state.responsaveis.filter(c => c.parentesco == eParentesco.Pai);
      if (responsavelPai && responsavelPai.length > 0) {
        responsavelPai.forEach(item => {
          this.$store.commit("removeResponsavel", item);
        });
      }
    }
  }
}